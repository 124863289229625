import { MouseEvent, TouchEvent, useCallback, useMemo, useState } from "react";

import { alpha, Box, Stack } from "@mui/material";

import { useFormContext } from "react-hook-form";

import onibus from "../../assets/onibus-todas-vistas.png";
import mascara from "../../assets/svg/path111.svg";
import { IFormData, Marcacao } from "../tipos.ts";

import Legenda from "./legenda.tsx";
import Marca from "./marca.tsx";
import { IdQuadrante, QUADRANTES } from "./tipos.ts";
import { UndoBox } from "./undo-box.tsx";

const larguraQuadro = 50;
const raioCirculo = 20;

export type Cords = {
  x: number;
  y: number;
};

export default function Marcacoes() {
  const { watch, setValue } = useFormContext<IFormData>();
  const [coordenadasPonto, setCoordenadasPonto] = useState<Cords>();
  const [coordenadasPercentuaisPonto, setCoordenadasPercentuaisPonto] =
    useState<Cords>();
  const [pontoAtualOnMove, setpontoAtualOnMove] = useState<Cords>();
  const [coordenadasCliente, setCoordenadasCliente] = useState<Cords>();
  const [boxDimension, setBoxDimension] = useState<DOMRect>();
  const marcados = watch("marcacoes");

  const setMarcados = useCallback(
    (updater: (m: Marcacao[]) => Marcacao[]) => {
      setValue("marcacoes", updater(marcados));
    },
    [marcados, setValue],
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent<HTMLImageElement>) => {
      if (e.target instanceof HTMLImageElement) {
        const box = e.target.getBoundingClientRect();
        setBoxDimension(box);
        const pontoInicial = e.touches[0];
        if (boxDimension) {
          const cordsPontoNaImagem = {
            x: pontoInicial.clientX - boxDimension.x,
            y: pontoInicial.clientY - boxDimension.y,
          };
          const cordsPercentuaisNaImagem = {
            x: cordsPontoNaImagem.x / box.width,
            y: cordsPontoNaImagem.y / box.height,
          };
          const coordsClient = {
            x: pontoInicial.clientX,
            y: pontoInicial.clientY,
          };
          setCoordenadasCliente(coordsClient);
          setCoordenadasPercentuaisPonto(cordsPercentuaisNaImagem);
          setCoordenadasPonto(cordsPontoNaImagem);
        }
      }
    },
    [boxDimension],
  );

  const handleTouchMove = useCallback((e: TouchEvent<HTMLImageElement>) => {
    if (e.target instanceof HTMLImageElement) {
      const touch = e.touches.item(0);
      const coords = { x: touch.clientX, y: touch.clientY };
      setpontoAtualOnMove(coords);
      console.log(coords);
    }
  }, []);

  const handleContextMenu = useCallback(
    (e: MouseEvent<HTMLDivElement>) => e.preventDefault(),
    [],
  );

  const idQuadrante = useMemo(() => {
    if (!pontoAtualOnMove || !coordenadasCliente) return undefined;

    const { x: cx, y: cy } = coordenadasCliente;
    const { x, y } = pontoAtualOnMove;
    const dx = x - cx;
    const dy = y - cy;

    console.log({ cx, cy, x, y, dx, dy });

    if (
      Math.abs(dx) > larguraQuadro ||
      dx === 0 ||
      Math.abs(dx) < raioCirculo / 1.5
    )
      return undefined;
    if (
      Math.abs(dy) > larguraQuadro ||
      dy === 0 ||
      Math.abs(dy) < raioCirculo / 1.5
    )
      return undefined;

    const posLinha = dx < 0 ? "1" : "2";
    const posColuna = dy < 0 ? "a" : "b";

    const quadrante = posColuna + posLinha;

    console.log({ cx, cy, x, y, dx, dy, coordenada: quadrante });

    return quadrante as IdQuadrante;
  }, [coordenadasCliente, pontoAtualOnMove]);

  const handleTouchEnd = useCallback(
    (_e: TouchEvent<HTMLImageElement>) => {
      const marca = idQuadrante;
      if (coordenadasPercentuaisPonto && marca) {
        setMarcados((m) => [
          ...m,
          {
            x: coordenadasPercentuaisPonto.x,
            y: coordenadasPercentuaisPonto.y,
            codigo: QUADRANTES[marca].codigo,
          },
        ]);
      }
      setCoordenadasPonto(undefined);
    },
    [idQuadrante, coordenadasPercentuaisPonto, setMarcados],
  );
  const handleUndoClick = useCallback(
    (_e: MouseEvent) => setMarcados((m) => m.slice(0, -1)),
    [setMarcados],
  );

  return (
    <Stack gap={2}>
      <UndoBox onClick={handleUndoClick} disabled={marcados.length === 0} />
      <Legenda />
      <Stack flex={1} position="relative">
        <img
          src={onibus}
          alt="vistas ônibus"
          width="100%"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onContextMenu={handleContextMenu}
          style={{ touchAction: "none" }}
        />

        {coordenadasPonto && (
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            position="absolute"
            width={larguraQuadro * 2}
            height={larguraQuadro * 2}
            top={coordenadasPonto.y - larguraQuadro}
            left={coordenadasPonto.x - larguraQuadro}
            sx={{
              maskImage: `url(${mascara})`,
              maskSize: "100% 100%",
              maskClip: "border-box",
              pointerEvents: "none",
              ["& > ." + idQuadrante]: {
                backgroundColor: alpha("#000000", 0.5),
              },
              ["& > div"]: {
                backgroundColor: alpha("#000000", 0.2),
                alignItems: "center",
                justifyContent: "center",
                color: "orange",
              },
            }}
          >
            <Quadrante quadrante="a1" />
            <Quadrante quadrante="a2" />
            <Quadrante quadrante="b1" />
            <Quadrante quadrante="b2" />
          </Box>
        )}

        {coordenadasPonto && idQuadrante && (
          <Marca
            top={coordenadasPonto.y}
            left={coordenadasPonto.x}
            codigo={QUADRANTES[idQuadrante].codigo}
          />
        )}

        {boxDimension &&
          marcados.map((m) => (
            <Marca
              key={m.x}
              top={m.y * boxDimension.height}
              left={m.x * boxDimension.width}
              codigo={m.codigo}
            />
          ))}
      </Stack>
    </Stack>
  );
}

function Quadrante({ quadrante }: { quadrante: IdQuadrante }) {
  const Icone = QUADRANTES[quadrante].icone;

  return (
    <Stack className={quadrante}>
      <Icone />
    </Stack>
  );
}
