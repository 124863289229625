import { Suspense, useCallback } from "react";

import { AppBar, Box, Button, CardMedia, Stack, Toolbar } from "@mui/material";

import { Link, Outlet } from "react-router-dom";

import logoMobnit from "../assets/logo-mobnit.png";
import { useAuthContext } from "../services/auth.ts";

import Aguarde from "./aguarde.tsx";
import ErrorBoundary from "./error-boundary.tsx";

export default function MainLayout() {
  const { signOut, currentUser } = useAuthContext();
  const handleLogout = useCallback(() => signOut(), [signOut]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Aguarde />}>
        <Stack flex={1}>
          <AppBar
            elevation={0}
            sx={{
              bgcolor: "background.default",
              color: "secondary.main",
            }}
          >
            <Toolbar
              sx={{
                gap: 2,
                py: { xs: 1.5, md: 0 },
                pr: { xs: 0, md: 3.5 },
                pl: { xs: 1.5, md: 3.5 },
              }}
            >
              <Box mr="auto" component={Link} to="/coleta-dados">
                <CardMedia component="img" height={40} image={logoMobnit} />
              </Box>
              {currentUser && <Button onClick={handleLogout}>Logout</Button>}
            </Toolbar>
          </AppBar>
          <Stack mt={9.5} flex={1} alignItems="center" gap={4}>
            <Outlet />
          </Stack>
        </Stack>
      </Suspense>
    </ErrorBoundary>
  );
}
