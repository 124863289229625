import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";

import { router } from "./rotas/router.tsx";
import { theme } from "./services/theme.ts";

import "./services/datas.ts";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <SnackbarProvider
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            autoHideDuration={5000}
            variant="error"
          >
            <CssBaseline />
            <RouterProvider router={router} />
          </SnackbarProvider>
        </LocalizationProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
