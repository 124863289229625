import { MouseEvent } from "react";

import { Undo } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

interface Props {
  onClick: (_e: MouseEvent) => void;
  disabled: boolean;
}

export function UndoBox({ onClick, disabled }: Props) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      border="1px solid #d3d3d3"
      p={1}
    >
      <Typography fontSize={10} ml={1}>
        Toque na imagem e arraste para selecionar a marcação
      </Typography>

      <Box
        onClick={onClick}
        display="flex"
        sx={{ color: disabled ? "#d3d3d3" : "#000000" }}
      >
        <Undo />
      </Box>
    </Stack>
  );
}
