import { useId } from "react";

import { alpha } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { Controller, FieldPathByValue, useFormContext } from "react-hook-form";

import { IFormData, type SimNao } from "./tipos.ts";

export default function SimNao({
  label,
  id,
}: {
  label: string;
  id: FieldPathByValue<IFormData, SimNao>;
}) {
  const { control } = useFormContext();
  const labelId = useId();

  return (
    <Controller
      name={id}
      rules={{ required: true }}
      control={control}
      render={({
        field: { onChange, value, ref, ...field },
        fieldState: { error },
      }) => (
        <FormControl
          size="small"
          error={Boolean(error)}
          tabIndex={-1}
          ref={ref}
          sx={{
            borderRadius: "5px",
            px: 1,
            "&:focus, &:has(:focus)": { backgroundColor: alpha("#000", 0.05) },
          }}
        >
          <FormLabel id={labelId} sx={{ textAlign: "start" }}>
            {label}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby={labelId}
            onChange={(_e, selectedValue) => onChange(selectedValue)}
            value={value ?? null}
            {...field}
          >
            <FormControlLabel value="sim" control={<Radio />} label="Sim" />
            <FormControlLabel value="nao" control={<Radio />} label="Não" />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
