import {
  Document,
  Image,
  Page,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";

import prefeituraNiteroi from "../../assets/brasao-de-niteroi.png";
import legenda from "../../assets/legenda-vistoria.png";
import onibusTodasVistas from "../../assets/onibus-todas-vistas.png";
import { INFO_EQUIPAMENTOS } from "../../formulario/labels.ts";
import { IFormData } from "../../formulario/tipos.ts";
import { fmtDataSimples } from "../../services/formatacao.ts";
import { IUser } from "../auth/types.ts";

import { BlocoSimNao, Desmarcado, Marcado } from "./bloco-sim-nao.tsx";
import showPdfIcone from "./show-icone-pdf.tsx";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    flex: 1,
    paddingHorizontal: 7,
    paddingVertical: 20,
    fontSize: 14,
    gap: 5,
  },
  section: {
    margin: 1,
    padding: 1,
    flexGrow: 1,
  },
  titulo1: {
    fontFamily: "Courier-Oblique",
    fontSize: 24,
  },
  titulo2: { fontSize: 18, fontWeight: "bold" },
  titulo3: { fontSize: 14, fontWeight: "bold" },
  border: {
    border: "1px solid #000000",
    borderRadius: "7px",
  },
  cabecalho: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 20,
    gap: 30,
  },
  linha: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  texto: { fontSize: 12 },
  secaoOnibus: {
    display: "flex",
    margin: 10,
    padding: 1,
    flex: 1,
    gap: 5,
  },
});

// Create Document Component
export const VistoriaPdf = ({
  formulario,
  fiscal,
}: {
  formulario: IFormData;
  fiscal: IUser | undefined;
}) => (
  <Document>
    <Page size="A4" style={[styles.page, { fontSize: 12 }]}>
      <Cabecalho />

      <Descricao formulario={formulario} />

      <Vistoria formulario={formulario} />

      <Itens formulario={formulario} />

      <Notificacao formulario={formulario} fiscal={fiscal} />
    </Page>
  </Document>
);

function Cabecalho() {
  return (
    <View style={styles.cabecalho}>
      <View style={{ alignItems: "center" }}>
        <Image
          src={prefeituraNiteroi}
          style={{
            width: "40px",
          }}
        />
        <Text style={styles.titulo2}>NITERÓI</Text>
        <Text style={{ fontSize: "8px" }}>SEMPRE À FRENTE</Text>
      </View>
      <View style={{ alignItems: "center" }}>
        <Text style={styles.titulo1}>SUBSECRETARIA DE TRANSPORTES</Text>
        <Text style={styles.titulo3}>FISCALIZAÇÃO DO SISTEMA VIÁRIO</Text>
        <Text style={styles.titulo3}>LAUDO DE INSPEÇÃO VEICULAR - Ônibus</Text>
      </View>
    </View>
  );
}

function ListaEquipamentos({
  formulario,
  inicio,
  fim,
}: {
  formulario: IFormData;
  inicio: number;
  fim?: number;
}) {
  return INFO_EQUIPAMENTOS.slice(inicio, fim).map(({ id, label }) => (
    <BlocoSimNao
      key={id}
      label={label}
      sim={formulario.equipamentos[id] === "sim"}
    />
  ));
}

function Descricao({ formulario }: { formulario: IFormData }) {
  return (
    <View
      style={[
        styles.border,
        styles.section,
        styles.titulo3,
        { maxHeight: 90, gap: 7, padding: 5 },
      ]}
    >
      <View style={[styles.linha]}>
        <View style={{ alignItems: "center" }}>
          <Text>PROCESSO</Text>
          <Text>{formulario.processo}</Text>
        </View>
        <View style={{ alignItems: "center" }}>
          <Text>NIT</Text>
          <Text>{formulario.nit}</Text>
        </View>
        <View style={{ alignItems: "center" }}>
          <Text>PLACA</Text>
          <Text>{formulario.placa}</Text>
        </View>
        <View style={{ alignItems: "center" }}>
          <Text>ANO FAB.</Text>
          <Text>{formulario.anoFabricacao}</Text>
        </View>
      </View>
      <View style={styles.linha}>
        <View style={[styles.linha, { gap: 5 }]}>
          <Text>EMPRESA:</Text>
          <Text>{formulario.empresa}</Text>
        </View>
        <View style={[styles.linha, { gap: 5 }]}>
          <Text>MARCA / MODELO:</Text>
          <Text>{`${formulario.marcaChassi}  / ${formulario.modelo}`}</Text>
        </View>
      </View>
      <View style={styles.linha}>
        <View style={[styles.linha, { gap: 5 }]}>
          <Text>VALIDADE DA DESINSETIZAÇÃO:</Text>
          <Text>
            {fmtDataSimples.format(
              formulario.validadeDesinsetizacao ?? undefined,
            )}
          </Text>
        </View>
        <View style={[styles.linha, { gap: 5 }]}>
          <Text>SELO:</Text>
          <Text>{formulario.seloNumero}</Text>
        </View>
      </View>
    </View>
  );
}

function Vistoria({ formulario }: { formulario: IFormData }) {
  return (
    <View style={[styles.secaoOnibus]}>
      <View>
        <View style={{ gap: 20, paddingBottom: 10 }}>
          <View style={[styles.linha]}>
            <View style={{ position: "relative" }}>
              <Image
                src={onibusTodasVistas}
                style={{ width: 424, height: 230 }}
              />
            </View>
            <View style={{ marginRight: -10, marginTop: 20 }}>
              <Image src={legenda} style={{ width: 140 }} />
            </View>
            {formulario.marcacoes.map((m, index) => (
              <Svg
                key={index}
                style={{
                  position: "absolute",
                  top: (m.y ?? 0) * 230 - 7,
                  left: (m.x ?? 0) * 424 - 7,
                  width: 14,
                  height: 14,
                }}
                viewBox="-2 -2 12 12"
                fillOpacity={0.001}
                fill="white"
                stroke="orange"
                strokeWidth={1}
              >
                {showPdfIcone(m.codigo) ?? ""}
              </Svg>
            ))}
          </View>
        </View>
        <View style={styles.linha}>
          <Text>
            CONDUTOR: <Text>{formulario.condutor}</Text>
          </Text>
          <Text>
            DATA VISTORIA: <Text>{formulario.dataVistoria}</Text>
          </Text>
        </View>
      </View>
    </View>
  );
}

function Itens({ formulario }: { formulario: IFormData }) {
  return (
    <View style={[styles.border, { padding: 7 }]}>
      <Text style={{ alignSelf: "center" }}>
        ITENS E EQUIPAMENTOS OBRIGATÓRIOS
      </Text>
      <View style={[styles.linha, { fontSize: "10px", marginTop: 10 }]}>
        <View>
          <View
            style={[
              styles.linha,
              { justifyContent: "flex-start", gap: 4, marginLeft: 0.5 },
            ]}
          >
            <Text>SIM</Text>
            <Text>NÃO</Text>
          </View>
          <ListaEquipamentos formulario={formulario} inicio={0} fim={14} />
        </View>
        <View>
          <View
            style={[
              styles.linha,
              { justifyContent: "flex-start", gap: 4, marginLeft: 0.5 },
            ]}
          >
            <Text>SIM</Text>
            <Text>NÃO</Text>
          </View>
          <ListaEquipamentos formulario={formulario} inicio={14} fim={27} />
        </View>
        <View>
          <View
            style={[
              styles.linha,
              { justifyContent: "flex-start", gap: 4, marginLeft: 0.5 },
            ]}
          >
            <Text>SIM</Text>
            <Text>NÃO</Text>
          </View>
          <ListaEquipamentos formulario={formulario} inicio={27} />
        </View>
      </View>
    </View>
  );
}

function Notificacao({
  formulario,
  fiscal,
}: {
  formulario: IFormData;
  fiscal: IUser | undefined;
}) {
  return (
    <View
      style={[styles.linha, styles.texto, { height: 125, marginTop: "auto" }]}
    >
      <View
        style={[
          styles.border,
          styles.section,
          {
            justifyContent: "flex-start",
            gap: 7,
            paddingTop: 10,
            paddingLeft: 5,
          },
        ]}
      >
        <View style={[styles.linha, { justifyContent: "flex-start", gap: 20 }]}>
          <Text>Notificação:</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            {formulario.notificacao === "nao" ? <Marcado /> : <Desmarcado />}
            <Text>NÃO</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            {formulario.notificacao === "sim" ? <Marcado /> : <Desmarcado />}
            <Text>SIM</Text>
          </View>
          <Text>
            N°. <Text>{formulario.numeroNotificacao}</Text>
          </Text>
        </View>

        <Text>
          {formulario.reparosRealizados
            ? `Reparos Realizados: ${formulario.reparosRealizados}`
            : "Reparos Realizados:"}
        </Text>
      </View>

      <View
        style={[
          styles.border,
          styles.section,
          { alignItems: "center", width: 165, fontSize: 10 },
        ]}
      >
        <Text
          style={{
            padding: 2,
          }}
        >
          Atesto que o veículo se encontra em acordo com a legislação de
          transportes do município e apto a prestar o serviço de transporte
          coletivo.
        </Text>
        <View style={{ marginTop: "auto" }}>
          <Text>{fiscal?.profile.name}</Text>
          <Text>Fiscal Vistoriador</Text>
        </View>
      </View>
    </View>
  );
}
