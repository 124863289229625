import { Box, Stack, SvgIcon } from "@mui/material";

import showIcone from "./show-icone.tsx";

export default function Legenda() {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        ["& div"]: { fontSize: 10, p: 0.5 },
        ["&  .MuiStack-root"]: { alignItems: "center", direction: "row" },
      }}
    >
      <Stack direction="row">
        <SvgIcon>{showIcone("quebrado")}</SvgIcon>
        <Box>Quebrado</Box>
      </Stack>
      <Stack direction="row">
        <SvgIcon>{showIcone("riscado")}</SvgIcon>
        <Box>Riscado</Box>
      </Stack>
      <Stack direction="row">
        <SvgIcon>{showIcone("batido")}</SvgIcon>
        <Box>Batido</Box>
      </Stack>
      <Stack direction="row">
        <SvgIcon>{showIcone("naoOperacional")}</SvgIcon>
        <Box>Não Operacional</Box>
      </Stack>
    </Stack>
  );
}
