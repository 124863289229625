import { Fragment, useCallback } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { confirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { usePdfVistoria } from "../components/pdf/hooks.tsx";
import { fmtDataSimples } from "../services/formatacao.ts";

import CampoData from "./inputs/date-picker.tsx";
import { INFO_EQUIPAMENTOS, ROTULOS } from "./labels.ts";
import Marcacoes from "./marcacao/marcacoes.tsx";
import Notificacao from "./notificacao.tsx";
import SimNao from "./sim-nao.tsx";
import { IFormData } from "./tipos.ts";

export default function Formulario() {
  const form = useForm<IFormData>({
    defaultValues: {
      dataVistoria: fmtDataSimples.format(new Date()),
      marcacoes: [],
      validadeDesinsetizacao: null,
    },
  });
  const [setInputs, pdfVistoria, resetPdf] = usePdfVistoria();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleDownload = useCallback(() => {
    setTimeout(() => {
      resetPdf();
      navigate("/coleta-dados");
    }, 1_000);
  }, [navigate, resetPdf]);

  const submit = useCallback(
    async (inputs: IFormData) => {
      await confirm({
        confirmationButtonProps: { autoFocus: true },
        title: "Confirmação",
        description:
          "Atesto que o veículo se encontra em acordo com a legislação de transportes do município e apto a prestar o serviço de transporte coletivo.",
        confirmationText: "Sim",
        cancellationText: "Não",
      })
        .then(() => {
          setInputs({ ...inputs });
        })
        .catch();
    },
    [setInputs],
  );
  const handleSubmitError = useCallback(() => {
    enqueueSnackbar("Todos os campos são obrigatórios");
  }, [enqueueSnackbar]);

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(submit, handleSubmitError)}
      >
        <Stack gap={2} p={2} sx={{ maxWidth: { xs: "100vw", md: "500px" } }}>
          <Alert severity="info" sx={{ mb: 1.5 }}>
            Preencha o formulário de vistoria do veículo
          </Alert>
          {ROTULOS.map((r) => (
            <Fragment key={r.id}>
              {r.id === "validadeDesinsetizacao" ? (
                <CampoData label={r.label} name={r.id} />
              ) : (
                <TextField
                  size="small"
                  variant="outlined"
                  label={r.label}
                  disabled={Boolean(r.id === "dataVistoria")}
                  {...form.register(r.id, { required: true })}
                />
              )}
            </Fragment>
          ))}
          <Marcacoes />
          <Typography color="primary.main" fontSize={20}>
            Equipamentos
          </Typography>

          {INFO_EQUIPAMENTOS.map((e) => (
            <SimNao key={e.id} label={e.label} id={`equipamentos.${e.id}`} />
          ))}
          <Notificacao />
          <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button>
        </Stack>
      </Stack>

      {pdfVistoria && (
        <Dialog open={true}>
          <DialogTitle>Relatório Gerado</DialogTitle>
          <DialogContentText p={4}>
            Faça o download do PDF gerado e guarde como comprovante do
            relatório. Estes dados não poderão ser acessados novamente.
          </DialogContentText>
          <DialogActions>
            <Button
              href={pdfVistoria}
              download="relatorio.pdf"
              onClick={handleDownload}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </FormProvider>
  );
}
