import { Circle, Line, Polygon } from "@react-pdf/renderer";

export default function showPdfIcone(quadrante: string | undefined) {
  switch (quadrante) {
    case "riscado":
    case "a1":
      return <Line x1="1" y1="4.5" x2="9" y2="4.5" />;

    case "batido":
    case "a2":
      return (
        <>
          <Line x1={1} y1={1} x2={9} y2={9} />
          <Line x1={1} y1={9} x2={9} y2={1} />
        </>
      );

    case "quebrado":
    case "b1":
      return (
        <>
          <Circle cx="5" cy="5" r="4.5" />
          <Line x1={1.5} y1={8.5} x2={8.5} y2={1.5} />
        </>
      );

    case "naoOperacional":
    case "b2":
      return <Polygon points="0,10 5,0 10,10 " />;

    default:
      return <></>;
  }
}
