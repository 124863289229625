import { alpha, createTheme } from "@mui/material";

import "@fontsource/plus-jakarta-sans/latin.css";

export const theme = createTheme({
  typography: { fontFamily: "Plus Jakarta Sans" },

  palette: {
    primary: { main: "#f5792b", contrastText: "#ffffff" },
    secondary: { main: "#83879A", contrastText: "#ffffff" },
    background: { default: "#ffffff" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ["& div#root"]: {
            display: "flex",
            minHeight: "100vh",
            width: "100vw",
            backgroundColor: "#FFFFFF",
          },
          ["& iframe"]: {
            width: "100%",
            height: "100%",
            border: 0,
          },

          scrollbarColor: "#c6c6c6 transparent",
          ["& *::-webkit-scrollbar"]: {
            backgroundColor: "transparent",
          },
          ["& *::-webkit-scrollbar-thumb"]: {
            borderRadius: 8,
            backgroundColor: "#c6c6c6",
            minHeight: 24,
            opacity: 0.5,
            ["&:hover"]: { backgroundColor: "#a6a6a6" },
            ["&:focus"]: { backgroundColor: "#a6a6a6" },
            ["&:active"]: { backgroundColor: "#a6a6a6" },
          },

          ["& *::-webkit-scrollbar-corner"]: {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: { fullWidth: true },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: { textTransform: "none" },
        contained: { fontWeight: 700 },
      },
    },
    MuiToggleButton: { styleOverrides: { root: { textTransform: "none" } } },
    MuiInputBase: { styleOverrides: { input: { backgroundColor: "white" } } },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 18,
          boxShadow: "0px 4px 16px -7px rgba(176, 176, 176, 0.21)",
        },
      },
    },
    MuiMenu: { styleOverrides: { paper: { borderRadius: 0 } } },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: "1px solid rgba(200, 200, 200, 0.25)",
          boxShadow: `0px 0px 0px 0px rgba(232, 232, 232, 0.05), 
                      0px 1px 3px 0px rgba(232, 232, 232, 0.05), 
                      0px 5px 5px 0px rgba(232, 232, 232, 0.04), 
                      0px 11px 6px 0px rgba(232, 232, 232, 0.03), 
                      0px 19px 8px 0px rgba(232, 232, 232, 0.01), 
                      0px 30px 8px 0px rgba(232, 232, 232, 0.00)`,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderColor: "rgba(131, 135, 154, 0.19)" },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: "100%",
          border: "1px solid rgba(131, 135, 154, 0.19)",
          borderRadius: "18px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: alpha(theme.palette.secondary.main, 0.19),
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          textTransform: "none",
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& :first-of-type": { borderRadius: "18px 0 0 0" },
          "& :last-child": { borderRadius: "0 18px 0 0" },
        },
      },
    },
  },
});
