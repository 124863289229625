import { Box, SvgIcon } from "@mui/material";

import { CodigoMarcacao } from "../tipos.ts";

import showIcone from "./show-icone.tsx";

interface Props {
  left: number;
  top: number;
  codigo: CodigoMarcacao;
}

export default function Marca({ codigo, left, top }: Props) {
  return (
    <Box
      position="absolute"
      alignItems="center"
      justifyContent="center"
      top={top - 12}
      left={left - 4}
      sx={{ pointerEvents: "none" }}
    >
      <SvgIcon sx={{ width: 10, height: 10 }}>{showIcone(codigo)}</SvgIcon>
    </Box>
  );
}
