import { Paper, Stack } from "@mui/material";

import BotaoLink from "./botao-link.tsx";

export default function Menu() {
  return (
    <Stack>
      <Stack
        component={Paper}
        p={2}
        sx={{ maxWidth: { xs: "vw", md: 800 }, gap: 2 }}
      >
        <BotaoLink to="/coleta-dados/vistoria">Iniciar Nova Vistoria</BotaoLink>
        {import.meta.env.DEV && (
          <>
            <BotaoLink disabled to="/coleta-dados/cadastro">
              Ver Vistorias anteriores
            </BotaoLink>{" "}
            <BotaoLink to="/coleta-dados/pdf">Visualizar Pdf</BotaoLink>
          </>
        )}
      </Stack>
    </Stack>
  );
}
