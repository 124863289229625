import { ReactNode } from "react";

import { CircularProgress, Stack, Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
}

export default function Aguarde({ children }: Props) {
  return (
    <Stack flex={1} display="flex" alignItems="center" justifyContent="center" gap={4}>
      {children ?? <Typography variant="caption">Aguarde, carregando…</Typography>}
      <CircularProgress />
    </Stack>
  );
}
