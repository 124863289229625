import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "dayjs/locale/pt-br.js";

dayjs.locale("pt-br");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Sao_Paulo");

export function formataData(
  valorComoString: string,
  formatador: Intl.DateTimeFormat,
) {
  const data = interpretaData(valorComoString);

  return data ? formatador.format(data) : valorComoString;
}

/**
 * Interpreta uma data que veio como string da API. Suporta yyyy-mm-dd,
 * com as horas opcionalmente em HH:mm:ss. Também é suportado informar em segundos
 * após o unix epoch.
 */
export function interpretaData(valorComoString: string) {
  if (!valorComoString) return null;

  const comoNumero = +valorComoString;
  if (String(comoNumero) === valorComoString) return comoNumero * 1000;

  return dayjs(valorComoString).toDate();
}

/* export function calculaValoresPadraoFiltroData(dataAtual = new Date()): [dayjs.Dayjs, dayjs.Dayjs] {
  const mesAnterior = dayjs.utc(dataAtual).subtract(1, "month");

  return [mesAnterior.startOf("month"), mesAnterior.endOf("month")];
} */
