import { Stack, TextField } from "@mui/material";

import { useFormContext } from "react-hook-form";

import SimNao from "./sim-nao.tsx";
import { IFormData } from "./tipos.ts";

export default function Notificacao() {
  const { watch, register } = useFormContext<IFormData>();
  const watchNotificacao = watch("notificacao");

  return (
    <Stack>
      <SimNao label="Notificação" id="notificacao" />
      {watchNotificacao === "sim" && (
        <Stack gap={1}>
          <TextField
            variant="outlined"
            label="N°"
            inputProps={{ ...register("numeroNotificacao") }}
          />
          <TextField
            variant="outlined"
            multiline
            minRows={4}
            label="Reparos Realizados"
            inputProps={{ ...register("reparosRealizados") }}
          />
        </Stack>
      )}
    </Stack>
  );
}
