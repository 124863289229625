import { ElementType } from "react";

import {
  Block,
  ChangeHistory,
  Close,
  HorizontalRule,
} from "@mui/icons-material";

import { CodigoMarcacao } from "../tipos.ts";

export type IdQuadrante = "a1" | "a2" | "b1" | "b2";

type InfoQuadrante = { codigo: CodigoMarcacao; icone: ElementType };

type MapaQuadrantes = Record<IdQuadrante, InfoQuadrante>;

export const QUADRANTES: MapaQuadrantes = {
  a1: {
    codigo: "riscado",
    icone: HorizontalRule,
  },
  a2: {
    codigo: "batido",
    icone: Close,
  },
  b1: {
    codigo: "quebrado",
    icone: Block,
  },
  b2: {
    codigo: "naoOperacional",
    icone: ChangeHistory,
  },
} as const;
