import { Line, Polygon, Svg, Text, View } from "@react-pdf/renderer";

export function BlocoSimNao({ label, sim }: { label: string; sim: boolean }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: 3,
      }}
    >
      {sim ? <Marcado /> : <Desmarcado />}
      {sim ? <Desmarcado /> : <Marcado />}
      <Text>{label}</Text>
    </View>
  );
}

export function Desmarcado() {
  return (
    <Svg height={10} width={20}>
      <Polygon points="0,0 20,0 20,10 0,10" stroke="black" strokeWidth={1} />
    </Svg>
  );
}

export function Marcado() {
  return (
    <Svg height={10} width={20}>
      <Polygon points="0,0 20,0 20,10 0,10" stroke="black" strokeWidth={1} />
      <Line x1={0} y1={0} x2={20} y2={10} stroke="black" strokeWidth={1} />
      <Line x1={0} y1={10} x2={20} y2={0} stroke="black" strokeWidth={1} />
    </Svg>
  );
}
