export default function showIcone(quadrante: string | undefined) {
  switch (quadrante) {
    case "riscado":
    case "a1":
      return (
        <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="4.5" x2="9" y2="4.5" stroke="orange" />
        </svg>
      );
    case "batido":
    case "a2":
      return (
        <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <line x1={1} y1={1} x2={9} y2={9} stroke="orange" />
          <line x1={1} y1={9} x2={9} y2={1} stroke="orange" />
        </svg>
      );

    case "quebrado":
    case "b1":
      return (
        <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="5"
            cy="5"
            r="4.5"
            stroke="orange"
            strokeWidth={1}
            fill="transparent"
          />

          <line
            x1={2.5}
            y1={7.5}
            x2={7.5}
            y2={2.5}
            stroke="orange"
            strokeWidth={1}
          />
        </svg>
      );
    case "naoOperacional":
    case "b2":
      return (
        <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <polygon
            points="0,10 5,0 10,10"
            stroke="orange"
            strokeWidth={1}
            fill="transparent"
          />
        </svg>
      );
    default:
      return "";
  }
}
