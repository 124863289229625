import { useCallback, useEffect, useState } from "react";

import { usePDF } from "@react-pdf/renderer";

import { IFormData } from "../../formulario/tipos.ts";
import { useAuthContext } from "../../services/auth.ts";

import { VistoriaPdf } from "./pdf-vistoria.tsx";

/**
 * Retorna uma tupla contendo, na primeira posição, uma função que, ao receber o objeto representando o formulário,
 * prepara o PDF e retorna sua URL para download na segunda posição da tupla.
 */
export function usePdfVistoria() {
  const [inputs, setInputs] = useState<IFormData>();
  const [urlPdf, setUrlPdf] = useState<string>();
  const { currentUser } = useAuthContext();
  const [{ loading, error, blob }, processa] = usePDF();

  const resetPdf = useCallback(() => {
    setUrlPdf((url) => {
      if (url) URL.revokeObjectURL(url);

      return undefined;
    });
  }, []);

  useEffect(() => {
    if (inputs)
      processa(
        <VistoriaPdf formulario={inputs} fiscal={currentUser ?? undefined} />,
      );
  }, [currentUser, inputs, processa]);

  useEffect(() => {
    if (loading || error || !blob) return;

    setUrlPdf((url) => {
      if (url) URL.revokeObjectURL(url);

      return URL.createObjectURL(blob);
    });

    return resetPdf;
  }, [blob, error, loading, resetPdf]);

  return [setInputs, urlPdf, resetPdf] as const;
}
