import { createBrowserRouter } from "react-router-dom";

import { AuthProvider, RequireAuth } from "../components/auth";
import MainLayout from "../components/main-layout.tsx";
import Menu from "../components/menu.tsx";
import Raiz from "../components/raiz.tsx";
import Formulario from "../formulario";
import { MobnitRouteObject } from "../types.ts";

export const routes: MobnitRouteObject[] = [
  {
    element: <AuthProvider />,
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          { element: <Raiz />, index: true },
          {
            element: <RequireAuth />,
            path: "coleta-dados",
            children: [
              { index: true, element: <Menu /> },
              { path: "vistoria", element: <Formulario /> },
              { path: "cadastro", element: <Formulario /> },
            ],
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
