import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

import dayjs from "dayjs";
import { Controller, FieldPath } from "react-hook-form";

import { IFormData } from "../tipos.ts";

interface Props {
  name: FieldPath<IFormData>;
  label: string;
}

export default function CampoData({ name, label }: Props) {
  return (
    <Controller
      render={({ field: { onChange, value, ref } }) => (
        <DatePicker
          {...BASE_PROPS}
          label={label}
          onChange={onChange}
          value={value}
          ref={ref}
        />
      )}
      name={name}
    />
  );
}
const BASE_PROPS: DatePickerProps<dayjs.Dayjs> = {
  slotProps: {
    textField: { size: "small" },
  },
};
