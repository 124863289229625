import { FieldPath } from "react-hook-form";

import { IFormData, InfoEquipamento } from "./tipos.ts";

export const ROTULOS: {
  id: FieldPath<IFormData>;
  label: string;
}[] = [
  { label: "Data da Vistoria", id: "dataVistoria" },
  { label: "Nº Ordem", id: "numeroOrdem" },
  { label: "Processo", id: "processo" },
  { label: "NIT", id: "nit" },
  { label: "Empresa", id: "empresa" },
  { label: "Placa", id: "placa" },
  { label: "Ano Fabricação", id: "anoFabricacao" },
  { label: "Marca Chassi", id: "marcaChassi" },
  { label: "Número Chassi", id: "numeroChassi" },
  { label: "Modelo", id: "modelo" },
  { label: "Selo nº", id: "seloNumero" },
  { label: "Validade da desinsetização", id: "validadeDesinsetizacao" },
  { label: "Condutor", id: "condutor" },
  { label: "Lugares sentados", id: "lugaresSentados" },
  { label: "Lugares em pé", id: "lugaresEmPe" },
] as const;

export const INFO_EQUIPAMENTOS: InfoEquipamento[] = [
  { id: "arCondicionado", label: "Ar condicionado" },
  { id: "placasLegiveisELacradas", label: "Placas legíveis e lacradas" },
  { id: "paraChoques", label: "Para-choques" },
  { id: "limpadorParaBrisa", label: "Limpador de para-brisa" },
  { id: "paraBrisa", label: "Para-brisa" },
  { id: "vidrosLateraisETraseiros", label: "Vidros laterais e traseiros" },
  { id: "buzina", label: "Buzina" },
  { id: "cintosSeguranca", label: "Cintos de segurança" },
  { id: "faixasLaterais", label: "Faixas laterais" },
  { id: "carroceria", label: "Carroceria" },
  { id: "portasPortasPcd", label: "Portas / Portas PCD" },
  { id: "bancosBancosPcd", label: "Bancos / Bancos PCD" },
  { id: "sinaleiraSinaleiraPCD", label: "Sinaleira / Sinaleira PCD" },
  { id: "lanternaPlaca", label: "Lanterna da placa" },
  { id: "lanternasTraseiras", label: "Lanternas traseiras" },
  { id: "lanternaFreio", label: "Lanterna do freio / Break Light" },
  { id: "lanternaMarchaRe", label: "Lanterna de marcha à ré" },
  {
    id: "lanternaIndicadoresDirecao",
    label: "Lanterna indicadores de direção",
  },
  { id: "lanternasAlerta", label: "Lanternas de alerta" },
  { id: "farolBaixo", label: "Farol baixo" },
  { id: "farolAlto", label: "Farol alto" },
  { id: "letreirosVistas", label: "Letreiros (Vistas)" },
  { id: "retrovisoresExternos", label: "Retrovisores externos" },
  { id: "retrovisorInterno", label: "Retrovisor interno" },
  { id: "iluminacaoSalao", label: "Iluminação do salão" },
  { id: "tarifa", label: "Valor da tarifa" },
  { id: "itensAcessibilidade", label: "Itens de acessibilidade" },
  { id: "pneus", label: "Pneus" },
  { id: "extintor", label: "Extintor" },
  { id: "triangulo", label: "Triângulo" },
  { id: "limpezaInternaExterna", label: "Limpeza interna e externa" },
  { id: "balaustres", label: "Balaustres" },
  { id: "catracas", label: "Catracas" },
  {
    id: "inscricoesObrigatorias",
    label: "Inscrições obrigatórias",
  },
  { id: "pisoAntiderrapante", label: "Piso antiderrapante" },
  { id: "elevador", label: "Elevador" },
  { id: "janelas", label: "Janelas" },
  { id: "tacografo", label: "Tacógrafo" },
  { id: "gps", label: "GPS" },
  { id: "camera", label: "Câmera" },
];
