import { useCallback, useEffect } from "react";

import { Button, Paper, Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../services/auth.ts";

export default function Raiz() {
  const { currentUser, signInIntegrated } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) navigate("coleta-dados");
  }, [currentUser, navigate]);

  const handleLogin = useCallback(
    () => signInIntegrated().catch(reportError),
    [signInIntegrated],
  );

  return (
    <Stack
      component={Paper}
      gap={3}
      p={2}
      border="1px solid #f5792b"
      maxWidth="200px"
    >
      <Typography textAlign="center">
        Bem-vindo ao cadastro de veículos.
      </Typography>
      <Button variant="contained" onClick={handleLogin}>
        Login
      </Button>
    </Stack>
  );
}
